import { gql } from "@apollo/client";

export const NAV_HEADER_QUERY = gql`
  query NavHeaderQuery {
    GetCartCount {
      count
    }
    GetNotiCount {
      count
    }
    GetLoginUser {
      ok
      error
      items {
        id
        isVerifiedPhone
      }
    }
  }
`;

export const DELETE_USER_BLOCK_LIST = gql`
  mutation DeleteUserBlockList($blockUserId: Int!) {
    result: DeleteUserBlockList(blockUserId: $blockUserId) {
      ok
      error
    }
  }
`;
