import React, { AnchorHTMLAttributes } from "react";
import { Link as InternalLink } from "react-router-dom";

interface UniversalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
}

const WEB_ENDPOINT = import.meta.env.VITE_ENDPOINT as string;

const UniversalLink = ({ to, children, ...props }: UniversalLinkProps) => {
  const isExternalLink = /^(https?:)?\/\//.test(to);
  // react-router 외부로 나가는 링크
  if (isExternalLink || to.startsWith("/v2")) {
    return (
      <a
        href={isExternalLink ? to : `${WEB_ENDPOINT}${to}`}
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }
  // react-router 내부에 있는 링크
  return (
    <InternalLink to={to as string} {...props}>
      {children}
    </InternalLink>
  );
};

export default UniversalLink;
