import React from "react";

import KoFooter from "./ko-footer";
import JaFooter from "./ja-footer";

import { isJapan } from "Functions/LanguageUtil";

const PrivacyUsage = () => {
  if (isJapan) {
    return <JaFooter />;
  }
  return <KoFooter />;
};

export default PrivacyUsage;
