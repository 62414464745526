import styled from "styled-components";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";

const BottomFloatingContainer = styled.div<{ bottomless?: boolean }>`
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  left: 0;
  right: 0;
  ${({ bottomless }) =>
    bottomless ? "bottom: 0;" : "bottom: var(--bottom-nav-bar-height);"}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: calc(16px + ${getSafeAreaInsetBottom("env")});
  background-color: #fff;
  z-index: 1;
`;

export default BottomFloatingContainer;
