import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { toastTop } from "../../../Functions/OtlToast";

import CheckOnIcon from "images/icons/checkbox_on.png";
import CheckOffIcon from "images/icons/checkbox_off.png";
import CartBaseIcon from "images/icons/cart_base.png";
import CancelIcon from "images/icons/ic_cancel.png";
import MinusIcon from "images/icons/remove.png";
import PlusIcon from "images/icons/add.png";
import { GetCartQuery } from "types/generated/graphql";
import { useAmplitude } from "utils/amplitude/use-amplitude";
import FlashLoadingForFullScreen from "Components/Common/FlashLoadingForFullScreen";
import Footer from "Components/Footer";
import NavHeader from "Components/NavHeader";
import OtlPrice from "Components/Common/OtlPrice/otl-price-v2";
import { maxWidth } from "core/styles/mixin";
import Conrfrim from "Components/confirm";
import {
  convertLanguageToCurrency,
  toCurrencyCode,
} from "Functions/currency-util";
import useLangauge from "core/locale/use-language";
import BottomFloatingButton from "elements/layout/bottom-floating-button";

const Container = styled.section`
  height: 100%;
  width: 100%;
  ${maxWidth}
  margin: 0 auto !important;
  float: none !important;
`;

interface IProps {
  userCart: GetCartQuery;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeFromCart: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amountCart: any;
  excludeIds: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkout: any;
  toggleCheckbox: (id: number) => void;
  toggleAllCheckbox: (allItemIds: number[], selectedItemIds: number[]) => void;
}

const MainContents = styled.div`
  display: flex;
  flex-flow: column;
`;

const Top = styled.div``;

const Bottom = styled.div``;

const CheckboxTabLayer = styled.div`
  margin-right: 16px;
  display: flex;
  font-size: 13px;
  color: #111;
`;

const CheckAll = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

const DeleteSelected = styled.div`
  cursor: pointer;
`;

const MyCart = styled.div`
  margin-top: 14px;
  font-size: 20px;
  color: #111;
  font-weight: bold;
  margin-left: 32px;
  margin-bottom: 40px;
  padding-top: calc(var(--nav-bar-height) + var(--search-nav-bar-height));
`;

const Shop = styled.div`
  margin-left: 32px;
  margin-right: 16px;
  margin-top: 24px;
`;

const ShopName = styled.div`
  font-size: 16px;
  font-weight: bold;
  height: 70px;
  display: flex;
  align-items: center;
`;

const ItemLayer = styled.div<{ isSoldout?: boolean }>`
  margin-top: 10px;
  margin-bottom: 21px;
  ${({ isSoldout }) =>
    isSoldout &&
    `opacity: 0.4;
    `}
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductInfoFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const ProductInfoLastLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-top: 12px;
`;

const ProductName = styled.div`
  font-size: 15px;
  color: #111;
  margin-right: 14px;
`;

const Price = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #111;
`;

const OptionValue = styled.div`
  font-size: 13px;
  color: #999;
  margin-top: 3px;
`;

const AmountCalc = styled.div`
  height: 28px;
  display: flex;
  border: 1px solid #eee;
  align-items: center;
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 10px;
`;

const AmoundCalc = styled.img`
  width: 16px;
  height: 16px;
`;

const AmountBox = styled.div`
  font-size: 13px;
  color: #111;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1px;
  width: 28px;
`;

const ProductImageWrap = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  margin-right: 12px;

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-color: rgba(66, 66, 66, 0.1);
    border-radius: 12px;
    content: "";
  }
`;

const ProductImage = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 12px;
  border-radius: 12px;
  object-fit: cover;
`;

const TotalLayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 42px 16px 24px 32px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #999;
  font-weight: bold;
`;

const Right2 = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: bold;
  color: #999;
  align-items: center;
`;

const BigText = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #999;
`;

const BigTextRed = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ff2c58;
`;

const Text = styled.div`
  font-size: 15px;
  margin-left: 6px;
`;

const BrandName = styled.div`
  font-size: 12px;
  color: #333;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ProductLayer = styled.div`
  display: flex;
`;

const CheckBox = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const DeleteButton = styled.img`
  height: 24px;
  width: 24px !important;
`;

const EmptyText = styled.div`
  margin-top: 15px;
  margin-bottom: 22px;
  color: #999;
  display: flex;
  justify-content: center;
`;

const EmptyWrap = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 140px;
`;

const GotoHome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 28px;
  border-radius: 14px;
  color: #333;
  border: solid 1px #eee;
  font-size: 13px;
`;

const CartBase = styled.img`
  width: 24px;
  height: 24px;
`;

const doCheckout = (totalPrice, checkout, selectedItemIds) => {
  if (totalPrice <= 0) {
    toastTop("상품을 선택해주세요.");
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  checkout({ variables: { ids: selectedItemIds } });
};

const CartPresenter: React.FunctionComponent<IProps> = ({
  checkout,
  userCart,
  loading,
  removeFromCart,
  amountCart,
  excludeIds,
  toggleCheckbox,
  toggleAllCheckbox,
}) => {
  const trans = useTranslation();
  const { language } = useLangauge();
  const isJapan = language === "japanese";
  const { track } = useAmplitude();
  const [confirmState, setConfirmState] = useState({
    isOpen: false,
    message: "",
  });

  const allItemIds: number[] = [];

  if (
    userCart &&
    userCart.Cart &&
    userCart.Cart.items &&
    userCart.Cart.items.shops &&
    userCart.Cart.items.shops
  ) {
    // eslint-disable-next-line array-callback-return
    userCart.Cart.items.shops.map((shop) => {
      if (shop && shop.items) {
        // eslint-disable-next-line array-callback-return
        shop.items.map((item) => {
          if (item && item.product && item.productItem) {
            // allItemCount++;
            allItemIds.push(item.id);
            if (item.productItem.stock && item.productItem.stock < 1) {
              excludeIds.push(item.id);
            }
          }
        });
      }
    });
  }

  const selectedItemIds = allItemIds.filter((id) => !excludeIds.includes(id));

  const doRemoveSelectedItem = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    removeFromCart({ variables: { ids: selectedItemIds } });

    track("delete cart item", {
      productIds: selectedItemIds,
    });
  };

  let checkoutPrice = 0;
  let checkoutCount = 0;
  let checkoutDeliveryFee = 0;
  let totalPrice = 0;
  let isNeedLogin = false;
  const isEmpty = allItemIds.length < 1;

  if (userCart && userCart.Cart && userCart.Cart.errorCode) {
    isNeedLogin = userCart.Cart.errorCode === "NEED_LOGIN";
  }

  const shops = userCart?.Cart?.items?.shops || [];
  const products = shops[0]?.items || [];
  const currentCurrencyCode = products[0]?.product?.priceCurrentCode;
  return (
    <Container>
      <>
        {(loading || isNeedLogin) && <FlashLoadingForFullScreen />}
        {!loading && isEmpty && (
          <MainContents>
            <NavHeader
              showClose={true}
              showBack={false}
              showLogo={false}
              showCart={false}
              showNoti={false}
              endAdornment={
                <CheckboxTabLayer>
                  <CheckAll
                    onClick={() =>
                      toggleAllCheckbox(allItemIds, selectedItemIds)
                    }
                  >
                    {selectedItemIds.length > 0
                      ? trans.t("unselect_all")
                      : trans.t("select_all")}
                  </CheckAll>
                  <DeleteSelected
                    onClick={() => {
                      setConfirmState({
                        isOpen: true,
                        message: trans.t("products_delete_confirm_message"),
                      });
                    }}
                  >
                    {trans.t("delete_selected_products")}
                  </DeleteSelected>
                </CheckboxTabLayer>
              }
            />

            <MyCart>my cart</MyCart>
            <EmptyWrap>
              <CartBase src={String(CartBaseIcon)} />
              <EmptyText>{trans.t("cart_empty")}</EmptyText>
              {isJapan || (
                <Link to={"/collection"}>
                  <GotoHome>{trans.t("go_to_shopping")}</GotoHome>
                </Link>
              )}
            </EmptyWrap>
          </MainContents>
        )}
        {!loading && !isEmpty && userCart?.Cart?.items?.shops && (
          <MainContents>
            <Top>
              <NavHeader
                showClose={true}
                showBack={false}
                showLogo={false}
                showCart={false}
                showNoti={false}
                endAdornment={
                  <CheckboxTabLayer>
                    <CheckAll
                      onClick={() =>
                        toggleAllCheckbox(allItemIds, selectedItemIds)
                      }
                    >
                      {selectedItemIds.length > 0
                        ? trans.t("unselect_all")
                        : trans.t("select_all")}
                    </CheckAll>
                    <DeleteSelected
                      onClick={() => {
                        setConfirmState({
                          isOpen: true,
                          message: trans.t("products_delete_confirm_message"),
                        });
                      }}
                    >
                      {trans.t("delete_selected_products")}
                    </DeleteSelected>
                  </CheckboxTabLayer>
                }
              />

              <MyCart>my cart</MyCart>

              {
                // eslint-disable-next-line array-callback-return
                userCart.Cart.items.shops.map((market, index) => {
                  if (!market || !market?.shop) {
                    return null;
                  }

                  checkoutDeliveryFee += market.shop.deliveryFee || 0;
                  const marketProducts = market?.items || [];
                  const brandName = marketProducts[0]?.product?.brand?.name;
                  return (
                    <Shop key={index}>
                      <ShopName>{brandName}</ShopName>
                      {market.items &&
                        market.items.map((item, innerIndex) => {
                          let itemPrice;

                          if (item && item.product) {
                            const perPrice = item.product.salePrice
                              ? item.product.salePrice
                              : item.product.price;

                            itemPrice = (perPrice || 0) * (item.amount || 0);

                            const isSelected = selectedItemIds.includes(
                              item.id
                            );

                            if (isSelected) {
                              totalPrice += itemPrice;
                              checkoutPrice += itemPrice;
                              checkoutCount++;
                            }
                          }

                          const isSoldout = true;

                          // Biz 팀과 합의 후, 기존 구매 플로우를 막습니다.
                          // if (item && item.productItem) {
                          //   isSoldout = (item.productItem.stock || 0) < 1;
                          // }

                          let productUrl = "/";

                          if (item && item.product) {
                            productUrl = `/product/${item.product.id}`;

                            if (item.refererPostId) {
                              productUrl = `/product/${item.product.id}?r=${item.refererPostId}`;
                            }
                          }

                          return (
                            <React.Fragment key={innerIndex}>
                              {item && item.product && item.productItem && (
                                <ItemLayer isSoldout={isSoldout}>
                                  <BrandName>
                                    {item?.product?.brand?.name || ""}
                                  </BrandName>
                                  <ProductLayer>
                                    {isSoldout && (
                                      <CheckBox
                                        src={String(
                                          !excludeIds.includes(item.id)
                                            ? CheckOnIcon
                                            : CheckOffIcon
                                        )}
                                      />
                                    )}
                                    {!isSoldout && (
                                      <CheckBox
                                        src={String(
                                          !excludeIds.includes(item.id)
                                            ? CheckOnIcon
                                            : CheckOffIcon
                                        )}
                                        onClick={() => toggleCheckbox(item.id)}
                                      />
                                    )}
                                    <Link to={productUrl}>
                                      <ProductImageWrap>
                                        {item.product.imageUrl && (
                                          <ProductImage
                                            src={item.product.imageUrl}
                                          />
                                        )}
                                      </ProductImageWrap>
                                    </Link>
                                    <ProductInfo>
                                      <ProductInfoFirstLine>
                                        <Link to={productUrl}>
                                          <ProductName>
                                            {item.product.name}
                                          </ProductName>
                                        </Link>
                                        <DeleteButton
                                          src={String(CancelIcon)}
                                          onClick={() => {
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                            removeFromCart({
                                              variables: { ids: item.id },
                                            });
                                            const { product } = item;

                                            if (product) {
                                              track("delete cart item", {
                                                productIds: [product.id],
                                              });
                                            }
                                          }}
                                        />
                                      </ProductInfoFirstLine>
                                      <OptionValue>
                                        {item.productItem.size}
                                      </OptionValue>
                                      <ProductInfoLastLine>
                                        {isSoldout && (
                                          <div>{trans.t("out_of_stock")}</div>
                                        )}
                                        {!isSoldout && (
                                          <AmountCalc>
                                            <AmoundCalc
                                              src={String(MinusIcon)}
                                              onClick={() => {
                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                                amountCart({
                                                  variables: {
                                                    amount:
                                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                      item.amount! - 1,
                                                    id: item.id,
                                                  },
                                                });
                                              }}
                                            />
                                            <AmountBox>{item.amount}</AmountBox>
                                            <AmoundCalc
                                              src={String(PlusIcon)}
                                              onClick={() => {
                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                                amountCart({
                                                  variables: {
                                                    amount:
                                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                      item.amount! + 1,
                                                    id: item.id,
                                                  },
                                                });
                                              }}
                                            />
                                          </AmountCalc>
                                        )}
                                        <Price>
                                          <OtlPrice
                                            value={Number(itemPrice)}
                                            targetCurrencyCode={convertLanguageToCurrency(
                                              language
                                            )}
                                            currentCurrencyCode={toCurrencyCode(
                                              item.product.priceCurrentCode
                                            )}
                                          />
                                        </Price>
                                      </ProductInfoLastLine>
                                    </ProductInfo>
                                  </ProductLayer>
                                </ItemLayer>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </Shop>
                  );
                })
              }
            </Top>
            <Bottom>
              <TotalLayer>
                <Row>
                  <Label>{trans.t("quantity")}</Label>
                  <Right2>{trans.t("pieces", { checkoutCount })} </Right2>
                </Row>
                <Row>
                  <Label>{trans.t("subtotal")}</Label>
                  <Right2>
                    <BigText>
                      <OtlPrice
                        value={checkoutPrice}
                        targetCurrencyCode={convertLanguageToCurrency(language)}
                        currentCurrencyCode={toCurrencyCode(
                          currentCurrencyCode
                        )}
                      />
                    </BigText>
                  </Right2>
                </Row>
                <Row>
                  <Label>{trans.t("shipping_fee")}</Label>
                  <Right2>
                    <BigText>
                      <OtlPrice
                        value={checkoutDeliveryFee}
                        targetCurrencyCode={convertLanguageToCurrency(language)}
                        currentCurrencyCode={toCurrencyCode(
                          currentCurrencyCode
                        )}
                      />
                    </BigText>
                  </Right2>
                </Row>
                <Row>
                  <BigTextRed>{trans.t("total_price")}</BigTextRed>
                  <Right2>
                    <BigTextRed>
                      <OtlPrice
                        value={checkoutPrice + checkoutDeliveryFee}
                        targetCurrencyCode={convertLanguageToCurrency(language)}
                        currentCurrencyCode={toCurrencyCode(
                          currentCurrencyCode
                        )}
                      />
                    </BigTextRed>
                  </Right2>
                </Row>
              </TotalLayer>
              <BottomFloatingButton
                active={false}
                bottomless={true}
                onClick={() => {
                  track("order cart item", {
                    itemCount: checkoutCount,
                    totalPrice,
                    productIds: selectedItemIds,
                  });
                  doCheckout(totalPrice, checkout, selectedItemIds);
                }}
              >
                <OtlPrice
                  // value={checkoutPrice + checkoutDeliveryFee}
                  value={0}
                  targetCurrencyCode={convertLanguageToCurrency(language)}
                  currentCurrencyCode={toCurrencyCode(currentCurrencyCode)}
                />
                <Text>{trans.t("do_order")}</Text>
              </BottomFloatingButton>
            </Bottom>
            <Conrfrim
              isOpen={confirmState.isOpen}
              disableBackDrop={true}
              message={confirmState.message}
              submitButtonText={trans.t("delete")}
              cancelButtonText={trans.t("cancel")}
              onClickClose={() => {
                setConfirmState({
                  ...confirmState,
                  isOpen: false,
                });
              }}
              onClickSubmit={() => {
                setConfirmState({
                  ...confirmState,
                  isOpen: false,
                });
                doRemoveSelectedItem();
              }}
            />
            <Footer />
          </MainContents>
        )}
      </>
    </Container>
  );
};

export default CartPresenter;
