import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CloseIcon from "../../images/icons/ic_close.png";
import CartWithBadge from "../../Components/Common/CartWithBadge";
import NotiWithBadge from "../../Components/Common/NotiWithBadge";
import SearchIcon from "../../images/icons/ic-24-search.svg";
import ArrowBack from "../../images/icons/ic_back.png";
import SearchIcon2 from "../../images/icons/search.svg";
import SettingIcon from "../../images/icons/setting.png";

import { NAV_HEADER_QUERY } from "./NavHeaderQueries";

import { useAmplitude } from "utils/amplitude/use-amplitude";
import { useNativeEvent } from "core/channels/use-native-event";
import { isJapan } from "Functions/LanguageUtil";
import { isApp } from "Functions/UserAgentUtil";
import { isLoggedIn } from "Functions/LoginUtil";
import useStackNavigate from "core/navigator/use-stack-navigate";
import { maxWidth } from "core/styles/mixin";
import { useAppDownLoad } from "core/context/app-download-provider";
import { IC_LOGO } from "core/image/icons";

const Container = styled.header<{
  divider?: boolean;
  floating?: boolean;
  isExposeAppDownload?: boolean;
}>`
  width: 100%;
  position: fixed;
  ${maxWidth}
  top: ${({ isExposeAppDownload }) =>
    isExposeAppDownload ? "var(--app-download-button-height)" : "0"};
  z-index: 3;
  background: white;
  opacity: 0.95;
  ${({ divider }) =>
    divider &&
    `
    border-bottom: 1px solid #e1e1e1a6;

  `}

  ${({ floating }) =>
    floating &&
    `
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      border-bottom: 0;
  `}
`;

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 3 !important;
  ${maxWidth}
  margin: 0 auto !important;
  float: none !important;
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  height: 56px;
  display: flex;
  justify-content: space-between;
`;

const LeftWrap = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const RightWrap = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 18px;

  :empty {
    display: none;
  }
`;

const BrandLogo = styled.img<{ showBack?: boolean }>`
  max-width: 156px;
  height: 16px;
  margin-left: ${({ showBack }) => (showBack ? "0;" : "24px;")};
  margin-bottom: 0;
  transition: opacity 0.3s ease 0.3s;
  cursor: pointer;
`;

const CustomButtonWrap = styled.div`
  position: relative;
`;

const CustomButton = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 8px;
  vertical-align: bottom;
`;

const Rectangle = styled.div`
  width: 1px;
  height: 12px;
  background-color: #d8d8d8;
  opacity: 0.6;
  margin-left: 10px;
  margin-right: 8px;
`;

const Collection = styled.div`
  font-size: 14px;
  color: #333;
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CollectionWrap = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
`;

const ArrowBackIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 20px;
  margin-right: 8px;
  cursor: pointer;
`;

const CloseButton = styled(ArrowBackIcon)``;

const Badge = styled.div`
  position: absolute;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  background-color: #ff2c58;
  color: white;
  font-size: 11px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 1;
`;

const SearchWrap = styled.div`
  margin-left: 24px;
  margin-right: 4px;
  margin-top: 4px;
  width: 100%;
`;

const Search = styled.input`
  background-color: #f4f4f4;
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8px;
  border-radius: 6px;
  height: 38px;
  width: 100%;
  color: #111;
  padding: 0 16px 0 36px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 0;
  border: none;

  ::placeholder {
    color: #ccc;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-left: 4px;
  }
`;

const PromotionBanner = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  margin: 12px 24px 20px;
  border-radius: 8px;
  ${({ bgColor }) => !!bgColor && `background-color: ${bgColor};`}
`;

interface IProps {
  showLogo?: boolean;
  showNoti?: boolean;
  showBack?: boolean;
  showCart?: boolean;
  showSearch?: boolean;
  showSearchIcon?: boolean;
  showClose?: boolean;
  showInstallBannerOnly?: boolean;
  showSetting?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subComponent?: any;
  backToHome?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customLogo?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customMoveback?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  states?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  floating?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  divider?: any;
  endAdornment?: React.ReactNode;
  onClickClose?: () => void;
  centerAdorment?: React.ReactNode;
}

const PromotionImg = styled.img`
  width: 100%;
  height: 64px;
  max-width: 327px;
`;

const CenterWrap = styled.div`
  display: flex;
  align-items: center;
`;
const End = styled.div`
  display: flex;
  align-items: center;
`;

const getCurrentProfile = () => {
  const origin = window.location.origin;
  let profile = "";
  if (origin.includes("https://dev")) {
    profile = "DEV";
  } else if (origin.includes("https://stg")) {
    profile = "STG";
  } else if (origin.includes("http://")) {
    profile = "LOCAL";
  }

  return profile;
};

const HeadLayer = ({
  data,
  showLogo = true,
  showNoti = true,
  showClose = false,
  showBack = true,
  showCart = true,
  showSearch = false,
  showSearchIcon = false,
  showInstallBannerOnly = false,
  showSetting = false,
  backToHome = false,
  subComponent,
  customLogo,
  customMoveback,
  endAdornment,
  onClickClose,
  centerAdorment,
}) => {
  const { vibrate } = useNativeEvent();
  const { track } = useAmplitude();
  const navigate = useStackNavigate();

  const moveback = useCallback(
    (backToHome: boolean) => {
      try {
        if (backToHome) {
          navigate("/");
        } else if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate("/");
        }
      } catch (error) {
        navigate("/");
      }
    },
    [navigate]
  );

  const [adData] = useState([
    {
      icon: "https://images.onthelook.co.kr/pr/ad-gel.png",
      bgColor: "#7B4847",
      link: "/promotion/asics",
      source: "ad-gel",
    },
    {
      icon: "https://images.onthelook.co.kr/pr/ad-varsity.png",
      bgColor: "#6E7256",
      link: "/promotion/varsity",
      source: "ad-varsity",
    },
  ]);
  const [rand] = useState(Math.floor(Math.random() * adData.length));

  const trans = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promotion: any = adData[rand];
  const isHome: boolean = window.location.pathname === "/";
  const isShowPromotion: boolean = isHome && isApp() && false;
  const profile = getCurrentProfile();

  return (
    <HeaderContainer className="headroom-container">
      {/* <AppInstallPopup /> */}
      {!showInstallBannerOnly && (
        <React.Fragment>
          <Inner className="headroom-container-inner">
            <LeftWrap>
              {showSearch && (
                <Link
                  to={"/search/intro?autoFocus=true"}
                  onClick={() => {
                    track("click search field", {
                      source: "home",
                    });
                  }}
                >
                  <SearchWrap>
                    <Search placeholder={trans.t("search_placeholder") || ""} />
                  </SearchWrap>
                </Link>
              )}
              {showBack !== false && (
                <ArrowBackIcon
                  src={ArrowBack}
                  onClick={() => {
                    if (customMoveback) {
                      customMoveback();
                    } else {
                      moveback(backToHome);
                    }
                  }}
                  alt="back"
                />
              )}
              {showClose !== false && (
                <CloseButton
                  src={CloseIcon}
                  onClick={() => {
                    if (onClickClose) {
                      onClickClose();
                    } else {
                      navigate(-1);
                    }
                  }}
                />
              )}
              {showLogo && (
                <Link to="/">
                  <LogoWrap className={"logo-wrap"}>
                    <BrandLogo src={IC_LOGO} showBack={showBack} />
                    {!!profile && (
                      <CollectionWrap>
                        <Rectangle />
                        <Collection>{profile}</Collection>
                      </CollectionWrap>
                    )}
                  </LogoWrap>
                </Link>
              )}
              {!showLogo && customLogo && (
                <React.Fragment>{customLogo}</React.Fragment>
              )}
            </LeftWrap>
            <CenterWrap>{centerAdorment}</CenterWrap>
            <RightWrap>
              {showSearchIcon && (
                <Link to={"/search/intro"}>
                  <img
                    src={SearchIcon2}
                    alt="search icon"
                    width="32"
                    height="32"
                    style={{ marginLeft: 8 }}
                  />
                </Link>
              )}
              {showCart && (
                <CartWithBadge
                  count={
                    (data && data.GetCartCount && data.GetCartCount.count) || 0
                  }
                />
              )}
              {showNoti && (
                <NotiWithBadge
                  count={
                    (data && data.GetNotiCount && data.GetNotiCount.count) || 0
                  }
                />
              )}
              {showSetting && (
                <Link
                  to={"/settings"}
                  onClick={() => {
                    vibrate();
                  }}
                >
                  <CustomButtonWrap>
                    <CustomButton src={SettingIcon} />
                    {data &&
                      data.GetLoginUser &&
                      data.GetLoginUser.items &&
                      !data.GetLoginUser.items.isVerifiedPhone && (
                        <Badge>!</Badge>
                      )}
                  </CustomButtonWrap>
                </Link>
              )}
              {endAdornment && <End>{endAdornment}</End>}
            </RightWrap>
          </Inner>
          {isShowPromotion && (
            <Link
              to={promotion.link}
              onClick={() => {
                track("click ad banner", {
                  source: promotion.source,
                });
              }}
            >
              <PromotionBanner bgColor={promotion.bgColor}>
                <PromotionImg src={promotion.icon} />
              </PromotionBanner>
            </Link>
          )}
          {subComponent}
        </React.Fragment>
      )}
    </HeaderContainer>
  );
};
/**
 * @deprecated 이 컴포넌트는 더이상 사용되지 않습니다. elements/layout을 사용하세요.
 */
const NavHeader: React.FunctionComponent<IProps> = ({
  showLogo,
  showNoti,
  showBack,
  showCart,
  showSearch,
  showSearchIcon,
  showSetting,
  showClose,
  showInstallBannerOnly,
  subComponent,
  backToHome,
  customLogo,
  customMoveback,
  floating = false,
  divider = false,
  onClickClose,
  endAdornment,
  centerAdorment,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let headerData: any = null;
  const { isExposeAppDownload } = useAppDownLoad();

  if (isLoggedIn()) {
    // TODO: 안티패턴 수정이 필요함
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useQuery(NAV_HEADER_QUERY, {});

    headerData = data;
  }

  return (
    <Container
      className={"nav-header-container"}
      floating={floating}
      divider={divider}
      isExposeAppDownload={isExposeAppDownload}
    >
      <HeadLayer
        data={headerData}
        showLogo={showLogo}
        showNoti={showNoti}
        showBack={showBack}
        showClose={showClose}
        showCart={isJapan ? false : showCart}
        showSearch={showSearch}
        showSearchIcon={showSearchIcon}
        showInstallBannerOnly={showInstallBannerOnly}
        showSetting={showSetting}
        subComponent={subComponent}
        backToHome={backToHome}
        customLogo={customLogo}
        customMoveback={customMoveback}
        endAdornment={endAdornment}
        onClickClose={onClickClose}
        centerAdorment={centerAdorment}
      />
    </Container>
  );
};

export default NavHeader;
