import { CurrencyCode } from "Components/Common/OtlPrice/otl-price-v2";
import { SupportLanguage } from "core/locale/use-language";

const LANGUAGE_TO_CURRENCY_MAP: Record<SupportLanguage, CurrencyCode> = {
  korean: "KRW",
  japanese: "JPY",
  english: "USD",
};

export const convertLanguageToCurrency = (
  language: SupportLanguage
): CurrencyCode => {
  const currency = LANGUAGE_TO_CURRENCY_MAP[language];
  if (!currency) {
    throw new Error(`Unsupported language: ${language}`);
  }
  return currency;
};

export const toCurrencyCode = (
  code: string | null | undefined
): CurrencyCode => {
  if (code && ["KRW", "JPY", "USD"].includes(code)) {
    return code as CurrencyCode;
  }
  return "KRW";
};
