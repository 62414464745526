import React from "react";
import { Query, Mutation } from "@apollo/client/react/components";

import CartPresenter from "./CartPresenter";
import {
  GET_CART,
  REMOVE_FROM_CART,
  AMOUNT_CART,
  SET_CHECKOUT,
} from "./queries";

import { redirectLoginPage } from "Functions/LoginUtil";
import { sendPageViewEvent } from "Functions/AmplitudeUtil";
import { NAV_HEADER_QUERY } from "Components/NavHeader/NavHeaderQueries";

interface IState {
  excludeIds: number[];
}

class CartContainer extends React.Component<Record<string, unknown>, IState> {
  public state = {
    excludeIds: [],
  };

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(props) {
    super(props);
    sendPageViewEvent("cart", 1, "");
  }

  public render() {
    const { excludeIds } = this.state;

    return (
      <Query
        query={GET_CART}
        fetchPolicy="network-only"
        onCompleted={(res) => {
          if (res.Cart && res.Cart.ok && res.Cart.items) {
            const soldout: number[] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any, array-callback-return
            res.Cart.items.shops.map((shop: any) => {
              // eslint-disable-next-line array-callback-return
              shop.items.map((item) => {
                if (item && item.productItem.stock < 1) {
                  soldout.push(item.id);
                }
              });
            });

            const v = [...this.state.excludeIds, ...soldout];

            this.setState({ excludeIds: v });
          } else if (res.Cart.errorCode === "NEED_LOGIN") {
            redirectLoginPage();
          }
        }}
      >
        {({ data, loading, refetch }) => {
          return (
            <Mutation
              mutation={REMOVE_FROM_CART}
              onCompleted={() => refetch()}
              refetchQueries={[NAV_HEADER_QUERY]}
            >
              {(removeFromCart) => (
                <Mutation mutation={AMOUNT_CART} onCompleted={() => refetch()}>
                  {(amountCart) => (
                    <Mutation
                      mutation={SET_CHECKOUT}
                      onCompleted={(res) => {
                        if (res.SetCheckout.ok) {
                          window.location.href = "/checkout";
                        }
                      }}
                    >
                      {(checkout) => (
                        <CartPresenter
                          loading={loading}
                          checkout={checkout}
                          userCart={data}
                          removeFromCart={removeFromCart}
                          amountCart={amountCart}
                          excludeIds={excludeIds}
                          toggleCheckbox={this.toggleCheckbox}
                          toggleAllCheckbox={this.toggleAllCheckbox}
                        />
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public toggleAllCheckbox = (allItemIds: any, selectedItemIds: any) => {
    if (selectedItemIds.length > 0) {
      this.setState({ excludeIds: allItemIds });
    } else {
      this.setState({ excludeIds: [] });
    }
  };

  public toggleCheckbox = (id: number) => {
    const same = this.state.excludeIds.filter((excludeId) => excludeId === id);

    let newExcludeId;

    if (same.length > 0) {
      newExcludeId = this.state.excludeIds.filter(
        (excludeId) => excludeId !== id
      );
    } else {
      newExcludeId = this.state.excludeIds;
      newExcludeId.push(id);
    }

    this.setState({
      excludeIds: newExcludeId,
    });
  };
}

export default CartContainer;
