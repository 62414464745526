import React, { useEffect } from "react";
import styled from "styled-components";

import { useChannelIOApi } from "core/context/third-party/channel-talk-provider";
import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { UniversalLink } from "elements/link";

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
  color: #666;
  padding-bottom: calc(48px + ${getSafeAreaInsetBottom("env")});
`;

const TopLayer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px 32px 24px;
  font-weight: bold;

  a {
    font-weight: 600;
    text-decoration: none;
    color: #666;
  }
`;

const PolicyLayer = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 12px;
  }
`;

const BottomLayer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 16px;
`;

const Copyright = styled.div`
  font-size: 11px;
`;

const MiddleLayer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-bottom: 16px;
`;

const IconLayer = styled.div`
  margin-left: 32px;
  margin-bottom: 16px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  img {
    margin-right: 14px;
    opacity: 0.7;
  }
`;
const SubTitle = styled.h2`
  font-size: 11px;
  color: #666;
`;
const Text = styled.div`
  font-size: 11px;
  color: #666;
  display: flex;
  align-items: center;
  min-height: 17px;

  a {
    font-size: 11px;
    color: #666;
  }
`;
const Button = styled.button`
  font-size: 11px;
  color: #000;
  display: flex;
  align-items: center;
  min-height: 17px;
  text-decoration: underline;
  font-weight: bold;
`;
const PhoneNumber = styled(Text)`
  color: #000;
  font-weight: bold;
`;
const SmallText = styled.span`
  font-size: 10px;
  color: #666;
  font-weight: 400;
  margin-left: 5px;
`;

const Footer = () => {
  const { showMessenger, hideMessenger } = useChannelIOApi();
  useEffect(() => {
    return () => hideMessenger();
  });
  return (
    <Container>
      <TopLayer>
        <PolicyLayer>
          <UniversalLink
            to="/v2/ja/terms/usage-of-service?otl-custom-tab=true"
            target="_blank"
          >
            利用規約
          </UniversalLink>
          <UniversalLink
            to="/v2/ja/terms/privacy-policy?otl-custom-tab=true"
            target="_blank"
          >
            プライバシーポリシー
          </UniversalLink>
          <a
            href="/terms-of-commerce?otl-custom-tab=true"
            target="_blank"
            rel="noreferrer"
          >
            特定商取引法に基づく表示
          </a>
        </PolicyLayer>
      </TopLayer>
      <MiddleLayer>
        <Text>株式会社onthelook</Text>
        <Text>郵便番号 04782</Text>
        <Text>韓国 ソウル特別市 城東区阿次山路104 4F 401</Text>
        <Text>事業者登録番号 : 658-86-01253</Text>
        <Text>通販事業報告書 : 2019-ソウル城東-01454</Text>
        <Text>CEO: Daebeom Lee(イ・デボム)</Text>
        <Text>メール : help@onthelook.co.kr</Text>
        <Text>
          <a href="tel:+827043530009">Contact: +82-70-4353-0009</a>
        </Text>
      </MiddleLayer>
      <MiddleLayer>
        <SubTitle>Customer Center</SubTitle>
        <PhoneNumber>
          (050)-5532-1047
          <SmallText> お電話での受付は現在休止しております</SmallText>
        </PhoneNumber>
        <Button onClick={showMessenger}>チャンネルトークチャット</Button>
        <Text>10:00~17:00 (昼休み 13:00~14:00）</Text>
        <Text>土日・韓国の祝日OFF</Text>
      </MiddleLayer>
      <BottomLayer>
        <Copyright>© 2020 onthelook. All Rights Reserved.</Copyright>
      </BottomLayer>
      <IconLayer>
        <a
          href="https://www.instagram.com/onthelook.japan/?otl-custom-tab=true"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://images.onthelook.co.kr/insta_icon.png"
            width="32"
            alt="instagram icon"
          />
        </a>
        <a
          href="https://twitter.com/onthelook_japan?otl-custom-tab=true"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://images.onthelook.co.kr/twitter_icon.png"
            width="32"
            alt="Twitter Icon"
          />
        </a>
        <a
          href="https://www.tiktok.com/@onthelook_japan?otl-custom-tab=true"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://images.onthelook.co.kr/tiktok_icon.png"
            width="32"
            alt="Tiktok Icon"
          />
        </a>
      </IconLayer>
    </Container>
  );
};

export default Footer;
