/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { NumericFormat } from "react-number-format";

export type CurrencyCode = "KRW" | "JPY" | "USD";

interface CurrencyInfo {
  EXCHANGE_RATE: number;
  SUFFIX: string;
  PREFIX: string;
}

/*
  targetCurrencyCode: 표기하려는 국가의 통화(사용자 국가의 통화)
  currentCurrencyCode: 현재 price가 어느 국가의 통화를 기준으로 입력되어 있는지
*/
interface OtlPriceProps {
  value: number;
  targetCurrencyCode: CurrencyCode;
  currentCurrencyCode: CurrencyCode;
}

const CURRENCY_CODE: Record<CurrencyCode, CurrencyInfo> = {
  KRW: {
    EXCHANGE_RATE: 1,
    SUFFIX: "",
    PREFIX: "￦",
  },
  USD: {
    EXCHANGE_RATE: 0.00076,
    SUFFIX: "",
    PREFIX: "$",
  },
  JPY: {
    EXCHANGE_RATE: 0.13,
    SUFFIX: "円",
    PREFIX: "",
  },
};

const OtlPrice = ({
  value,
  targetCurrencyCode,
  currentCurrencyCode,
}: OtlPriceProps) => {
  const currentCurrency = CURRENCY_CODE[currentCurrencyCode];
  const targetCurrency = CURRENCY_CODE[targetCurrencyCode];

  if (!currentCurrency || !targetCurrency) return <>{value}</>;

  const convertedPrice =
    (value / currentCurrency.EXCHANGE_RATE) * targetCurrency.EXCHANGE_RATE;

  return (
    <>
      {targetCurrency.PREFIX}
      <NumericFormat
        value={convertedPrice.toFixed(0)}
        thousandSeparator={true}
        displayType={"text"}
      />
      {targetCurrency.SUFFIX}
    </>
  );
};

export default OtlPrice;
