import { gql } from "@apollo/client";

export const SET_CHECKOUT = gql`
  mutation SetCheckout($ids: [Int]!) {
    SetCheckout(ids: $ids) {
      ok
      error
      errorCode
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($ids: [Int]!) {
    RemoveFromCart(ids: $ids) {
      ok
      error
      errorCode
    }
  }
`;

export const AMOUNT_CART = gql`
  mutation AmountCart($id: Int!, $amount: Int!) {
    AmountCart(id: $id, amount: $amount) {
      ok
      error
      errorCode
    }
  }
`;

export const GET_CART = gql`
  query GetCart {
    Cart {
      ok
      error
      errorCode
      items {
        user {
          id
          name
        }
        shops {
          shop {
            name
            deliveryFee
          }
          items {
            id
            product {
              id
              name
              imageUrl
              brand {
                id
                name
                imageUrl
              }
              price
              salePrice
              category
              subCategory
              priceCurrentCode
            }
            productItem {
              id
              size
              stock
            }
            amount
            refererPostId
          }
        }
      }
    }
  }
`;
