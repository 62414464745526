import React from "react";
import { Button } from "@onthelook/ui";

import FlashLoading from "Components/Common/FlashLoading";
import BottomFloatingContainer from "core/styles/bottom-floating-container";

interface IButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  active?: boolean;
  bottomless?: boolean;
}

const BottomFloatingButton = ({
  onClick,
  children,
  loading = false,
  active = true,
  bottomless = false,
}: IButtonProps) => (
  <BottomFloatingContainer bottomless={bottomless}>
    <Button
      color={"primary"}
      behavior="fluid"
      disable={!active}
      onClick={() => {
        if (active && !loading && onClick) onClick();
      }}
    >
      {loading ? <FlashLoading color="#fff" marginTop={0} /> : <>{children}</>}
    </Button>
  </BottomFloatingContainer>
);

export default BottomFloatingButton;
