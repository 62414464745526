import { useAuth } from "core/context/auth-provider";
import { useStackNavigate } from "core/navigator";
import useUserAgent from "core/user-agent/use-user-agent";
import { Product } from "types/generated/graphql";

const BASE_URL = "https://m.onthelook.store";
const CAFE24_BRIDGE_URL = BASE_URL + "/bridge";
const CAFE24_ORDER_LIST_URL = BASE_URL + "/myshop/order/list.html";
const CAFE24_STORE_ORDER_URL = BASE_URL + "/myshop/order/detail.html";
const CAFE24_STORE_EXCHANGE_URL = BASE_URL + "/myshop/order/exchange.html";
const CAFE24_STORE_RETURN_URL = BASE_URL + "/myshop/order/return.html";
const CAFE24_PRODUCT_URL = BASE_URL + "/product";
// const CAFE24_CART_URL = BASE_URL + "/order/basket.html";
const CAFE24_REVIEW_URL = BASE_URL + "/board/product/write.html";
const CAFE24_STORE_CANCEL_URL = BASE_URL + "/myshop/order/cancel.html";

export const useCommerceUrlGenerator = () => {
  /*  테스트 기간동안 isDevelopment 까지 */
  const { isSupportedOnthelookStore, isApp } = useUserAgent();
  const { user, isLoggedIn } = useAuth();
  const stackNavigate = useStackNavigate();

  const generateQueryString = () => {
    const queryStringParts: string[] = [];

    if (isApp) {
      queryStringParts.push("isAppMode=true");
    }

    if (!isLoggedIn) {
      queryStringParts.push("isLogOff=true");
      queryStringParts.push(`redirectUrl=${window.location.href}`);
    }

    if (user?.isConnectedCafe24) {
      queryStringParts.push(`isConnected=${user.isConnectedCafe24}`);
    }

    const queryString = queryStringParts.join("&");
    return queryString;
  };

  const isOpenOnthelookStore = (isCafe24Related: boolean) => {
    return isSupportedOnthelookStore && isCafe24Related;
  };

  const getOrderDetailUrl = ({
    isCafe24Order,
    orderNumber,
  }: {
    isCafe24Order: boolean;
    orderNumber: string;
  }) => {
    const queryString = generateQueryString();
    return isOpenOnthelookStore(isCafe24Order)
      ? `${CAFE24_BRIDGE_URL}?${
          queryString === "" ? "" : `${queryString}&`
        }target_url=${CAFE24_STORE_ORDER_URL}?order_id=${orderNumber}`
      : `/order/${orderNumber}`;
  };

  const getExchangeDetailUrl = ({
    isCafe24Order,
    orderNumber,
    orderItemId,
  }: {
    isCafe24Order: boolean;
    orderNumber: string;
    orderItemId: number;
  }) => {
    const queryString = generateQueryString();
    return isOpenOnthelookStore(isCafe24Order)
      ? `${CAFE24_BRIDGE_URL}?${
          queryString === "" ? "" : `${queryString}&`
        }target_url=${CAFE24_STORE_EXCHANGE_URL}?order_id=${orderNumber}`
      : `/exchange/${orderItemId}`;
  };

  const getCancelDetailUrl = ({
    isCafe24Order,
    orderNumber,
  }: {
    isCafe24Order: boolean;
    orderNumber: string;
  }) => {
    const queryString = generateQueryString();
    return isOpenOnthelookStore(isCafe24Order)
      ? `${CAFE24_BRIDGE_URL}?${
          queryString === "" ? "" : `${queryString}&`
        }target_url=${CAFE24_STORE_CANCEL_URL}?order_id=${orderNumber}`
      : "/";
  };

  const getReturnDetailUrl = ({
    isCafe24Order,
    orderNumber,
    orderItemId,
  }: {
    isCafe24Order: boolean;
    orderNumber: string;
    orderItemId: number;
  }) => {
    const queryString = generateQueryString();
    return isOpenOnthelookStore(isCafe24Order)
      ? `${CAFE24_BRIDGE_URL}?${
          queryString === "" ? "" : `${queryString}&`
        }target_url=${CAFE24_STORE_RETURN_URL}?order_id=${orderNumber}`
      : `/return/${orderItemId}`;
  };

  const getCartDetailUrl = () => {
    return "/cart";
    // return isOpenOnthelookStore(true)
    //   ? `${CAFE24_CART_URL}?${generateQueryString()}`
    //   : "/cart";
  };

  const getOrderListUrl = () => {
    return `${CAFE24_ORDER_LIST_URL}?${generateQueryString()}`;
  };

  const getReviewWriteUrl = ({ orderId, productNo }) => {
    return isOpenOnthelookStore(true)
      ? `${CAFE24_REVIEW_URL}?product_no=${productNo}&order_id=${orderId}`
      : "";
  };

  const getProductDetailUrl = ({
    isRegisteredCafe24,
    cafe24ProductId,
    productName,
    productId,
    referrerPostIdParam,
  }: {
    isRegisteredCafe24: boolean;
    cafe24ProductId: number;
    productName: string;
    productId: number;
    referrerPostIdParam: string;
  }) => {
    // before: RE#9204 BK 서퍼브핏 헤비데미지드 데님 팬츠
    // after: re9204-bk-서퍼브핏-헤비데미지드-데님-팬츠
    const sanitizedProductName = productName
      .replace(/[^\w\dㄱ-ㅎㅏ-ㅣ가-힣 ]/g, "")
      .replace(/ /gi, "-")
      .toLocaleLowerCase();

    return isOpenOnthelookStore(isRegisteredCafe24)
      ? `${CAFE24_PRODUCT_URL}/${sanitizedProductName}/${cafe24ProductId}?${generateQueryString()}`
      : `/product/${productId}${referrerPostIdParam}`;
  };

  /**
   * m.onthelook.store로 이동하기 위한 스택 네비게이션 함수입니다.
   * App 환경
   * - 입점 제품: onthelook store 커스텀탭 또는 바텀탭 이동
   * - 미입점 제품: external 커스텀탭 이동
   *
   * Web 환경
   * - 입점 제품: window.open으로 이동
   * - 미입점 제품: react router로 이동
   */
  const stackNavigateForProduct = ({
    product,
    postId,
  }: {
    product: Omit<Product, "isBookmarked" | "connectOffers" | "status">;
    postId: number;
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isWeb } = useUserAgent();
    const saleLink = product.saleLink || "";

    // 미입점 제품은 개인소장, 시즌 종료 등으로 제품 링크가 없을 수 있습니다.
    // stackNavigateForProduct 함수를 호출하는 곳에서 이를 처리해야 합니다.
    // stackNavigateForProduct hook에서 모든 상태 및 UI 변경을 처리할 수 없습니다.
    if (!product.isRegisteredCafe24 && !saleLink) {
      return;
    }

    const productDetailUrl = getProductDetailUrl({
      isRegisteredCafe24: Boolean(product.isRegisteredCafe24),
      productId: product.id,
      productName: product.name || "",
      cafe24ProductId: Number(product.cafe24ProductId || 0),
      referrerPostIdParam: postId ? `?r=${postId}` : "",
    });

    // cafe24가 아닌 외부 제품의 경우 customTab에서 open합니다.
    // nonesale product는 장기적으로 제거합니다.
    // stackNavigation 지원불가한 경우 (2.0.0 미만) 도 window.open으로 처리합니다.
    if (isWeb && product.isRegisteredCafe24) {
      window.open(productDetailUrl, "_blank");
      return;
    }

    stackNavigate(productDetailUrl, {
      screenType: isOpenOnthelookStore(Boolean(product?.isRegisteredCafe24))
        ? "onthelook-store"
        : undefined,
    });
  };

  return {
    getOrderDetailUrl,
    getExchangeDetailUrl,
    getReturnDetailUrl,
    getProductDetailUrl,
    getCartDetailUrl,
    getReviewWriteUrl,
    isOpenOnthelookStore,
    getOrderListUrl,
    getCancelDetailUrl,
    stackNavigateForProduct,
  };
};
