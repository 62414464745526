import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import MenuCartIcon from "../../../images/icons/menu_cart.png";

const MenuCart = styled.img`
  width: 32px;
  height: 32px;
`;

const Badge = styled.div<{ right?: number }>`
  height: 16px;
  min-width: 16px;
  padding-left: 4.7px;
  padding-right: 4.7px;
  background-color: #ff2c58;
  color: white;
  font-size: 11px;
  position: absolute;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  ${({ right }) => `right: ${right}px;`}
  z-index: 1;
`;

const Wrap = styled.div`
  height: 32px;
  margin-left: 8px;
  position: relative;

  a {
    height: 100%;
    display: inline-block;
  }
`;

const Component = ({ count }) => {
  let right = 0;

  if (count <= 9) {
    right = 0;
  } else if (count <= 99) {
    right = -6;
  } else {
    right = -10;
  }

  return (
    <Wrap>
      <Link to={"/cart"}>
        <MenuCart src={MenuCartIcon} />
        {count > 0 && <Badge right={right}>{count < 99 ? count : "99+"}</Badge>}
      </Link>
    </Wrap>
  );
};

export default Component;
