import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import React, { Suspense } from "react";

import PublicOnlyRoute from "./public-only-route";
import Root from "./root-route";

import { lazyRetry } from "core/loader";
import { RootBoundary } from "core/error";
import ProtectedRoute from "router/protected-route";
import PostLayout from "Routes/post/post-detail/layout";
import PostDetailSkeleton from "Components/post-detail/post-detail-skeleton";
import BrandDetailSkeleton from "Routes/brand/brand-detail/brand-detail-skeleton";
import BrandDetailLayout from "Routes/brand/brand-detail/layout";
import CartContainer from "Routes/order/cart";

// const LongformLayout = lazyRetry(
//   () => import("Routes/video/video-layout"),
//   "LongformLayout"
// );
const HomeLayout = lazyRetry(() => import("Routes/home/layout"), "HomeLayout");
const CommentLayout = lazyRetry(
  () => import("Routes/comment/layout"),
  "CommentLayout"
);
const NotificationLayout = lazyRetry(
  () => import("Routes/Notifications/layout"),
  "NotificationLayout"
);
const DefaultLayout = lazyRetry(
  () => import("Components/layout/default-layout"),
  "DefaultLayout"
);
const UserMileageLayout = lazyRetry(
  () => import("Routes/MyPage/user-mileage/layout"),
  "UserMileageLayout"
);
const MyCouponsLayout = lazyRetry(
  () => import("Routes/MyPage/my-coupons/layout"),
  "MyCouponsLayout"
);
const OrderLayout = lazyRetry(
  () => import("Routes/order/layout"),
  "OrderLayout"
);
const ProductDetailLayout = lazyRetry(
  () => import("Routes/product/product-detail/layout"),
  "ProductDetailLayout"
);
const BookmarkLayout = lazyRetry(
  () => import("Routes/Bookmark/layout"),
  "BookmarkLayout"
);
const AboutCreatorLayout = lazyRetry(
  () => import("Routes/creator/about-creator/layout"),
  "AboutCreatorLayout"
);
const LoginLayout = lazyRetry(
  () => import("Routes/auth/login/layout"),
  "LoginLayout"
);
const FindIDPWLayout = lazyRetry(
  () => import("Routes/auth/find-id-pw/layout"),
  "FindIDPWLayout"
);
const EventLayout = lazyRetry(
  () => import("Routes/Promotion/Event/layout"),
  "EventLayout"
);
const StoreEventLayout = lazyRetry(
  () => import("Routes/Promotion/store/event/layout"),
  "StoreEventLayout"
);
const MySubscriptionLayout = lazyRetry(
  () => import("Routes/user/my-subscriptions/layout"),
  "MySubscriptionLayout"
);
const ReviewLayout = lazyRetry(
  () => import("Routes/product/review/layout"),
  "ReviewLayout"
);
const UserDetailLayout = lazyRetry(
  () => import("Routes/user/user-detail/layout"),
  "UserDetailLayout"
);
const RewardLayout = lazyRetry(
  () => import("Routes/creator/reward/layout"),
  "RewardLayout"
);
const EditCreatorProfileLayout = lazyRetry(
  () => import("Routes/creator/edit-creator-profile/layout"),
  "EditCreatorProfileLayout"
);
const CheckoutLayout = lazyRetry(
  () => import("Routes/order/checkout/layout"),
  "CheckoutLayout"
);
const EditOrderAddressLayout = lazyRetry(
  () => import("Routes/order/edit-order-address/layout"),
  "EditOrderAddressLayout"
);
const PostEditorLayout = lazyRetry(
  () => import("Routes/post/editor/layout"),
  "PostEditorLayout"
);
const ProfileSettingLayout = lazyRetry(
  () => import("Routes/auth/profile-setting/layout"),
  "ProfileSettingLayout"
);
const V2Redirect = lazyRetry(() => import("Routes/v2-redirect"), "V2Redirect");
const PromotionLayout = lazyRetry(
  () => import("Routes/Promotion/layout"),
  "PromotionLayout"
);
const PromotionCategoryLayout = lazyRetry(
  () => import("Routes/Promotion/category/layout"),
  "PromotionCategoryLayout"
);

const PostDetail = lazyRetry(
  () => import("Routes/post/post-detail"),
  "postDetail"
);

const Comments = lazyRetry(() => import("Routes/Comments"), "comments");
const ImageZoom = lazyRetry(
  () => import("Routes/post/image-zoom"),
  "imageZoom"
);
const BrandDetail = lazyRetry(
  () => import("Routes/brand/brand-detail"),
  "brandDetail"
);
const ProductDetail = lazyRetry(
  () => import("Routes/product/product-detail"),
  "productDetail"
);

const SprintDemoIntro = lazyRetry(
  () => import("Routes/onboarding/sprint-demo-intro"),
  "sprintDemoIntro"
);
const AboutCreator = lazyRetry(
  () => import("Routes/creator/about-creator"),
  "aboutCreator"
);
const UserDetail = lazyRetry(
  () => import("Routes/user/user-detail"),
  "userDetail"
);
const AboutCreatorQna = lazyRetry(
  () => import("Routes/creator/about-creator/qna"),
  "aboutCreatorQna"
);
const UserExit = lazyRetry(
  () => import("Routes/setting/user-exit"),
  "userExit"
);

const UserExited = lazyRetry(
  () => import("Routes/setting/user-exited"),
  "user-exited"
);

const RedirectUri = lazyRetry(
  () => import("Routes/RedirectUri"),
  "redirectUri"
);
// const StoreEvent = lazyRetry(
//   () => import("Routes/Promotion/store/event"),
//   "storeEvent"
// );
const AddReview = lazyRetry(
  () => import("Routes/product/review/AddReview"),
  "addReview"
);

const AppleLoginCallback = lazyRetry(
  () => import("Routes/auth/login/apple/callback"),
  "appleLoginCallback"
);
const CancelOrder = lazyRetry(
  () => import("Routes/order/cancel-order"),
  "cancelOrder"
);
const Checkout = lazyRetry(() => import("Routes/order/checkout"), "checkout");
const CompleteOrder = lazyRetry(
  () => import("Routes/order/complete-order"),
  "completeOrder"
);
const CompletePayment = lazyRetry(
  () => import("Routes/order/complete-payment"),
  "completePayment"
);
const CreatorOnboarding = lazyRetry(
  () => import("Routes/creator/onboarding"),
  "creatorOnboarding"
);
const EditCreatorProfile = lazyRetry(
  () => import("Routes/creator/edit-creator-profile"),
  "editCreatorProfile"
);
const EditLoginPassword = lazyRetry(
  () => import("Routes/setting/EditLoginPassword"),
  "editLoginPassword"
);
const EditOrderAddress = lazyRetry(
  () => import("Routes/order/edit-order-address"),
  "editOrderAddress"
);
const EditReview = lazyRetry(
  () => import("Routes/product/review/EditReview"),
  "editReview"
);
const EditUserInfo = lazyRetry(
  () => import("Routes/setting/EditUserInfo"),
  "editUserInfo"
);
const EditUserPhone = lazyRetry(
  () => import("Routes/setting/EditUserPhone"),
  "editUserPhone"
);
const EmailSignUp = lazyRetry(
  () => import("Routes/auth/signup"),
  "emailSignUp"
);
const Event = lazyRetry(() => import("Routes/Promotion/Event"), "event");
const FindIdPw = lazyRetry(() => import("Routes/auth/find-id-pw"), "findIdPw");
const KakaoLoginCallback = lazyRetry(
  () => import("Routes/auth/login/kakao/callback"),
  "kakaoLoginCallback"
);
const LanguageSetting = lazyRetry(
  () => import("Routes/setting/LanguageSetting"),
  "languageSetting"
);
const Login = lazyRetry(() => import("Routes/auth/login"), "login");
const MyCoupons = lazyRetry(
  () => import("Routes/MyPage/my-coupons"),
  "myCoupons"
);
const MySubscriptions = lazyRetry(
  () => import("Routes/user/my-subscriptions"),
  "mySubscriptions"
);
const Notification = lazyRetry(
  () => import("Routes/Notifications"),
  "notification"
);
const OrderDetail = lazyRetry(
  () => import("Routes/order/order-detail"),
  "orderDetail"
);
const OrderHistory = lazyRetry(
  () => import("Routes/order/order-history"),
  "orderHistory"
);
const CreatorReward = lazyRetry(
  () => import("Routes/creator/reward"),
  "CreatorReward"
);
const RewardHistory = lazyRetry(
  () => import("Routes/creator/reward/reward-history"),
  "rewardHistory"
);

const ProductReview = lazyRetry(
  () => import("Routes/product/review/ProductReview"),
  "productReview"
);
const PostEditor = lazyRetry(() => import("Routes/post/editor"), "postEditor");
const Settings = lazyRetry(() => import("Routes/setting"), "settings");
const UserMileage = lazyRetry(
  () => import("Routes/MyPage/user-mileage"),
  "userMileage"
);
const RedirectCreator = lazyRetry(
  () => import("Routes/RedirectCreator"),
  "redirectCreator"
);
const RedirectShopCreator = lazyRetry(
  () => import("Routes/RedirectShopCreator"),
  "redirectShopCreator"
);
const TermsOfCommerce = lazyRetry(
  () => import("Routes/terms/terms-of-commerce"),
  "transaction"
);
const UsageGuide = lazyRetry(
  () => import("Routes/terms/usage-guide"),
  "usageGuide"
);
const LineLoginCallback = lazyRetry(
  () => import("Routes/auth/login/line/callback"),
  "loginCallback"
);
const ProfileSetting = lazyRetry(
  () => import("Routes/auth/profile-setting"),
  "ProfileSetting"
);
const SearchV4 = lazyRetry(() => import("Routes/post/search-v4"), "searchV4");
const SearchResult = lazyRetry(
  () => import("Routes/post/search-result"),
  "searchResult"
);
const SearchIntro = lazyRetry(
  () => import("Routes/post/search-intro"),
  "searchIntro"
);
const SearchResultLayout = lazyRetry(
  () => import("Routes/post/search-result/layout"),
  "searchResultLayout"
);
const SearchIntroLayout = lazyRetry(
  () => import("Routes/post/search-intro/layout"),
  "searchIntroLayout"
);
const SearchV4Layout = lazyRetry(
  () => import("Routes/post/search-v4/layout"),
  "searchV4Layout"
);
const MyPageLayout = lazyRetry(
  () => import("Routes/MyPage/layout"),
  "myPageLayout"
);
const MyPage = lazyRetry(() => import("Routes/MyPage"), "myPage");
const Onboarding = lazyRetry(() => import("Routes/onboarding"), "onboarding");
const Home = lazyRetry(() => import("Routes/home"), "home");
const Bookmark = lazyRetry(() => import("Routes/Bookmark"), "bookMark");
const OnthelookAt = lazyRetry(
  () => import("Routes/onthelook-at"),
  "onthelookAt"
);
const Return = lazyRetry(() => import("Routes/order/return"), "return");
const Exchange = lazyRetry(() => import("Routes/order/exchange"), "exchange");
const ExchangeConfirm = lazyRetry(
  () => import("Routes/order/exchange-confirm"),
  "exchangeConfirm"
);
const LongFormPlayer = lazyRetry(
  () => import("Routes/video/index"),
  "LongFormPlayer"
);
const SSOLogin = lazyRetry(
  () => import("Routes/cafe24/sso-login"),
  "sso-login"
);
// const Promotion = lazyRetry(() => import("Routes/Promotion"), "promotion");
// const Category = lazyRetry(
//   () => import("Routes/Promotion/category"),
//   "category"
// );
const InstagramInsight = lazyRetry(
  () => import("Routes/setting/instagram-insight/index"),
  "instagram-insight"
);
const YoutubeInsight = lazyRetry(
  () => import("Routes/setting/youtube-insight/index"),
  "youtube-insight"
);
const IfCreatorLookbookProduct = lazyRetry(
  () => import("Routes/iframe/creator-lookbook/product"),
  "iframe-creator-lookbook-product"
);
const IfCreatorLookbookBrand = lazyRetry(
  () => import("Routes/iframe/creator-lookbook/brand"),
  "iframe-creator-lookbook-brand"
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<RootBoundary />}>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/post" element={<PostLayout />}>
        <Route
          path={":id"}
          element={
            <Suspense fallback={<PostDetailSkeleton hideGnb={true} />}>
              <PostDetail />
            </Suspense>
          }
        />
      </Route>

      <Route path="/post/:id" element={<CommentLayout />}>
        <Route path={"/post/:id/comment"} element={<Comments />} />
      </Route>
      <Route
        path="/video"
        // element={<LongformLayout />}
        element={<Navigate replace to="/?tab=HOT" />}
      >
        <Route path={":id"} element={<LongFormPlayer />} />
      </Route>

      <Route element={<PostLayout />}>
        <Route path="/image-zoom" element={<ImageZoom />} />
      </Route>

      <Route path="/brand" element={<BrandDetailLayout />}>
        <Route
          path={":id"}
          element={
            <Suspense fallback={<BrandDetailSkeleton />}>
              <BrandDetail />
            </Suspense>
          }
        />
      </Route>

      <Route element={<ProductDetailLayout />}>
        <Route path={"/product/:id"} element={<ProductDetail />} />
      </Route>

      {/* Deprecated */}
      <Route path={"/shopProducts"} element={<Navigate replace to="/" />} />
      <Route path={"/collection"} element={<Navigate replace to="/" />} />

      <Route element={<SearchIntroLayout />}>
        {/* /searchV3는 depcreated 되었습니다. 외부 유입 가능성을 위해 남겨둡니다. */}
        <Route path={"/searchV3/intro"} element={<SearchIntro />} />
        <Route path={"/search/intro"} element={<SearchIntro />} />
      </Route>
      <Route element={<SearchResultLayout />}>
        {/* /searchV3는 depcreated 되었습니다. 외부 유입 가능성을 위해 남겨둡니다. */}
        <Route path={"/searchV3/result"} element={<SearchResult />} />
        <Route path={"/search/result"} element={<SearchResult />} />
      </Route>

      <Route path="/onboarding">
        <Route index element={<Onboarding />} />
        <Route path={"b/:step"} element={<Onboarding />} />
      </Route>

      <Route path={"/sprintDemoIntro/:gender"} element={<SprintDemoIntro />} />

      <Route element={<UserDetailLayout />}>
        <Route path={"/user/:id"} element={<UserDetail />} />
      </Route>

      <Route path="/about-creator" element={<AboutCreatorLayout />}>
        <Route index element={<AboutCreator />} />
        <Route path={":type"} element={<AboutCreatorQna />} />
      </Route>

      {/* Deprecated */}
      <Route path={"/explorer"} element={<Navigate replace to="/?tab=HOT" />} />
      {/* Deprecated */}
      <Route
        path={"/explorer/trend"}
        element={<Navigate replace to="/?tab=HOT" />}
      />
      {/* Deprecated */}
      <Route
        path={"/explorer/hot-category/:type"}
        element={<Navigate replace to="/?tab=HOT" />}
      />
      {/* Deprecated */}
      <Route
        path={"/explorer/popular-outer"}
        element={<Navigate replace to="/?tab=HOT" />}
      />

      {/* SearchV4 */}
      <Route element={<SearchV4Layout />}>
        <Route path={"/searchV4"} element={<SearchV4 />} />
      </Route>

      {/* /promotion은 서버에서 동적으로 데이터를 URL을 관리  */}
      <Route element={<EventLayout />}>
        <Route path={"/promotion/event"} element={<Event />} />
      </Route>
      <Route element={<StoreEventLayout />}>
        <Route
          path={"/promotion/store/event"}
          // element={<StoreEvent />}
          element={<Navigate replace to="/?tab=HOT" />}
        />
        {/* <Route path={"/showcase"} element={<Showcase />} /> */}
      </Route>
      <Route element={<PromotionLayout />}>
        <Route
          path={"/showcase"}
          // element={<Promotion />}
          element={<Navigate replace to="/?tab=HOT" />}
        />
      </Route>
      <Route element={<PromotionCategoryLayout />}>
        <Route
          path={"/promotion/category"}
          // element={<Category />}
          element={<Navigate replace to="/?tab=HOT" />}
        />
      </Route>

      {/* 약관 관련 Route URL 입니다. */}
      <Route path={"/terms-of-commerce"} element={<TermsOfCommerce />} />
      <Route path={"/usage-guide"} element={<UsageGuide />} />

      {/* 로그인 되어 있을 시, 접근이 불가능 한 페이지 */}
      <Route element={<PublicOnlyRoute />}>
        <Route element={<DefaultLayout bottomless />}>
          <Route path={"/email-signup"} element={<EmailSignUp />} />
        </Route>
        <Route element={<FindIDPWLayout />}>
          <Route path={"/find-id-pw"} element={<FindIdPw />} />
        </Route>
        <Route element={<ProfileSettingLayout />}>
          <Route
            path={"/sign-up/profile-setting"}
            element={<ProfileSetting />}
          />
        </Route>
        <Route path={"/login/line/callback"} element={<LineLoginCallback />} />
        <Route element={<LoginLayout />}>
          <Route path={"/login"} element={<Login />} />
        </Route>
      </Route>

      <Route path={"/appleLoginCallback"} element={<AppleLoginCallback />} />
      <Route path={"/kakaoLoginCallback"} element={<KakaoLoginCallback />} />
      <Route path={"/login/sso"} element={<SSOLogin />} />

      {/* 로그인이 필요한 페이지 */}
      <Route element={<ProtectedRoute />}>
        <Route element={<MySubscriptionLayout />}>
          <Route path={"/subscriptions"} element={<MySubscriptions />} />
        </Route>
        {/* 포스트 업로드 & 수정 관련 Route */}
        <Route element={<PostEditorLayout />}>
          <Route
            path={"/partners/upload/:loadTempPost"}
            element={<PostEditor />}
          />
          <Route path={"/partners/upload"} element={<PostEditor />} />
          <Route path={"/post/:id/edit"} element={<PostEditor />} />
        </Route>

        {/* 주문과 관련된 Route */}
        <Route element={<OrderLayout />}>
          <Route path={"/myorders"} element={<OrderHistory />} />
          <Route path={"/order/:orderNumber"} element={<OrderDetail />} />
          <Route path={"/return/:orderItemId"} element={<Return />} />
          <Route path={"/exchange/:orderItemId"} element={<Exchange />} />
          <Route
            path={"/exchange/confirm/:orderItemId"}
            element={<ExchangeConfirm />}
          />
        </Route>
        {/* <Route path={"/cart"} element={<Navigate replace to="/" />} /> */}
        <Route path={"/cart"} element={<CartContainer />} />
        <Route element={<CheckoutLayout />}>
          <Route path={"/checkout"} element={<Checkout />} />
        </Route>
        <Route element={<OrderLayout headerless />}>
          <Route path={"/completeOrder"} element={<CompleteOrder />} />
        </Route>
        <Route element={<OrderLayout />}>
          <Route path={"/cancelOrder"} element={<CancelOrder />} />
        </Route>
        <Route path={"/completePayment"} element={<CompletePayment />} />
        <Route element={<EditOrderAddressLayout />}>
          <Route path={"/edit-order-address"} element={<EditOrderAddress />} />
        </Route>
        {/* 주문과 관련된 Route 끝 */}

        <Route element={<MyPageLayout />}>
          <Route path={"/mypage"} element={<MyPage />} />
        </Route>

        <Route element={<UserMileageLayout />}>
          <Route path={"/user-mileage"} element={<UserMileage />} />
        </Route>

        <Route element={<MyCouponsLayout />}>
          <Route path={"/mycoupons"} element={<MyCoupons />} />
        </Route>

        <Route element={<NotificationLayout />}>
          <Route path={"/notification"} element={<Notification />} />
        </Route>

        <Route path="/bookmark" element={<BookmarkLayout />}>
          <Route index element={<Bookmark />} />
          <Route path={":type"} element={<Bookmark />} />
        </Route>

        <Route element={<DefaultLayout />}>
          <Route path={"/language-setting"} element={<LanguageSetting />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path={"/edit-user-info"} element={<EditUserInfo />} />
          <Route path={"/edit-user-phone"} element={<EditUserPhone />} />
          <Route
            path={"/edit-login-password"}
            element={<EditLoginPassword />}
          />
          <Route path={"/user-exit"} element={<UserExit />} />
          <Route path={"/instagram-insight"} element={<InstagramInsight />} />
          <Route path={"/youtube-insight"} element={<YoutubeInsight />} />
        </Route>

        <Route path={"/user-exited"} element={<UserExited />} />
        <Route element={<ReviewLayout />}>
          <Route path={"/productReviews/:id"} element={<ProductReview />} />
        </Route>
        <Route path={"/addReview/:productId"} element={<AddReview />} />
        <Route path={"/editReview/:id"} element={<EditReview />} />

        <Route element={<MyPageLayout />}>
          <Route path={"/partners"} element={<MyPage />} />
        </Route>
        <Route element={<RewardLayout />}>
          <Route path={"/partners/rewards"} element={<CreatorReward />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route
            path={"/partners/rewards/history"}
            element={<RewardHistory />}
          />
        </Route>
        <Route element={<EditCreatorProfileLayout />}>
          <Route
            path={"/partners/edit-creator-profile"}
            element={<EditCreatorProfile />}
          />
        </Route>
        {/* 알림톡에서 사용하는 Route */}
        <Route path={"/partners/onboarding"} element={<CreatorOnboarding />} />

        <Route path={"/onthelook-at"} element={<OnthelookAt />} />
      </Route>
      <Route
        path={"/agreement"}
        element={<V2Redirect to={"/v2/ko/terms/usage-of-service"} />}
      />
      <Route
        path={"/privacy"}
        element={<V2Redirect to={"/v2/ko/terms/privacy-policy"} />}
      />

      {/* Iframe Pages */}
      <Route
        path={"/if/lookbook/:brandName/cproduct/:id"}
        element={<IfCreatorLookbookProduct />}
      />
      <Route
        path={"/if/lookbook/brand/:id"}
        element={<IfCreatorLookbookBrand />}
      />

      {/* 셀렉샵 demo */}
      <Route path={"/s/:id"} element={<RedirectShopCreator />} />

      {/* 크레이터들이 자신의 인스타그램에서 프로필 링크에 홍보용으로 사용합니다 */}
      <Route path={"/:id"} element={<RedirectCreator />} />

      {/* redirect 용도로 사용 합니다. */}
      <Route path={"/ru/:id"} element={<RedirectUri />} />
    </Route>
  )
);

export default router;
