import React from "react";
import styled from "styled-components";

import { getSafeAreaInsetBottom } from "Functions/LayoutUtil";
import { UniversalLink } from "elements/link";

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
  color: #666;
  padding-bottom: calc(48px + ${getSafeAreaInsetBottom("env")});
`;

const TopLayer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px 32px 24px;
  font-weight: bold;

  a {
    font-weight: 600;
    text-decoration: none;
    color: #666;
  }
`;

const PolicyLayer = styled.div`
  display: flex;
  align-items: center;
`;

const Agreement = styled.div`
  margin-right: 12px;
`;

const Private = styled.div``;

const BottomLayer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 16px;
`;

const Copyright = styled.div`
  font-size: 11px;
`;

const MiddleLayer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-bottom: 16px;
`;

const IconLayer = styled.div`
  margin-left: 32px;
  margin-bottom: 16px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  img {
    margin-right: 14px;
    opacity: 0.7;
  }
`;

const Text = styled.div`
  font-size: 11px;
  color: #666;
  display: flex;
  align-items: center;
  min-height: 17px;

  a {
    font-size: 11px;
    color: #666;
  }
`;

const Footer = () => (
  <Container>
    <TopLayer>
      <PolicyLayer>
        <UniversalLink to="/v2/ko/terms/usage-of-service">
          <Agreement>Terms of Service</Agreement>
        </UniversalLink>
        <UniversalLink to="/v2/ko/terms/privacy-policy">
          <Private>Privacy Policy</Private>
        </UniversalLink>
      </PolicyLayer>
    </TopLayer>
    <MiddleLayer>
      <Text>onthelook Co., Ltd.</Text>
      <Text>104, Achasan-ro, 4F, 401, Seoul, Seongdong-gu 04782, KR</Text>
      <Text>Business registration number. 658-86-01253</Text>
      <Text>Mail-order business report: 2019-서울성동-01454</Text>
      <Text>CEO: Daebeom Lee</Text>
      <Text>Email: help@onthelook.co.kr</Text>
      <Text>
        <a href="tel:+827043530009">Contact: +82-70-4353-0009</a>
      </Text>
    </MiddleLayer>
    <BottomLayer>
      <Copyright>© 2020 onthelook. All Rights Reserved.</Copyright>
    </BottomLayer>
    <IconLayer>
      <a
        href="https://www.instagram.com/onthelook.korea/?otl-custom-tab=true"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://images.onthelook.co.kr/insta_icon.png"
          width="32"
          alt="instagram icon"
        />
      </a>
      <a
        href="https://blog.naver.com/onthelook_magazine?otl-custom-tab=true"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://images.onthelook.co.kr/blog_icon.png"
          width="32"
          alt="blog Icon"
        />
      </a>
      <a
        href="https://post.naver.com/onthelook_magazine?otl-custom-tab=true"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://images.onthelook.co.kr/post_icon.png"
          width="32"
          alt="post Icon"
        />
      </a>
    </IconLayer>
  </Container>
);

export default Footer;
