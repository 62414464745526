import React from "react";
import styled from "styled-components";

import { useNativeEvent } from "core/channels/use-native-event";
import { getReadableMessage } from "Functions/TextUtil";
import { maxWidth } from "core/styles/mixin";
import ModalPortal from "elements/modal/modal-portal";

const ModalOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 100;
  ${maxWidth}
  margin: auto;
`;

const PopupBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 300px;
  max-width: 600px;
  margin: auto;
  border-radius: 6px;
  border: 1px solid white;
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 32px 16px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eee;
`;

const PopupTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #111;
  margin-bottom: 12px;
`;

const PopupDesc = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: #111;
  font-weight: 400;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  font-size: 15px;
  height: 49px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  cursor: pointer;
`;

interface Props {
  isOpen: boolean;
  title?: string;
  message?: string;
  disableBackDrop?: boolean;
  submitButtonText: string;
  cancelButtonText: string;
  onClickBackDrop?: () => void;
  onClickClose: () => void;
  onClickSubmit: () => void;
}

const Conrfrim = ({
  isOpen,
  title,
  message,
  disableBackDrop,
  submitButtonText,
  cancelButtonText,
  onClickBackDrop,
  onClickClose,
  onClickSubmit,
}: Props) => {
  const { vibrate } = useNativeEvent();
  if (!isOpen) return null;

  return (
    <ModalPortal>
      <ModalOverLay
        onClick={() => {
          if (disableBackDrop) {
            vibrate();
          } else if (onClickBackDrop) {
            onClickBackDrop();
          } else {
            onClickClose();
          }
        }}
      >
        <PopupBodyWrap>
          <InfoWrap>
            {title && (
              <PopupTitle>{getReadableMessage({ text: title })}</PopupTitle>
            )}
            {message && (
              <PopupDesc>{getReadableMessage({ text: message })}</PopupDesc>
            )}
          </InfoWrap>
          <ButtonWrap>
            <Button
              onClick={() => {
                vibrate();
                onClickClose();
              }}
              style={{ borderRight: "1px solid #eee" }}
            >
              {cancelButtonText}
            </Button>
            <Button
              onClick={() => {
                vibrate();
                onClickSubmit();
              }}
              style={{ color: "#ff2c58" }}
            >
              {submitButtonText}
            </Button>
          </ButtonWrap>
        </PopupBodyWrap>
      </ModalOverLay>
    </ModalPortal>
  );
};

export default Conrfrim;
